import { putScrollbarSizeInCSSVariables } from './common/functions'
import { menuBurger } from './components/menuBurger'
import { openConfigurator, openFancyboxes } from './components/openFancyboxes'
import { scrollToAnchor } from './components/scrollToAnchor'

window.addEventListener('DOMContentLoaded', (event) => {
  /*
   * Feature code example
   * */
  putScrollbarSizeInCSSVariables()
  menuBurger()
  scrollToAnchor()
  openConfigurator()
  openFancyboxes()
})
