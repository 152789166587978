import { $ } from '../common/variables'

const fancyboxSize = {
  width: 1140,
  height: 800
}

const config = {
  Toolbar: {
    display: ['close']
  },
  Image: {
    zoom: false
  },
  dragToClose: false,
  touch: false,
  autoFocus: false
}

export const openConfigurator = () => {
  $('.js-configurator-open').on('click', function () {
    new Fancybox(
      [
        {
          src: 'assets/build/configurator/index.php',
          type: 'iframe',
          ...fancyboxSize
        }
      ],
      {
        ...config
      }
    )
  })
}

export const openFancyboxes = () => {
  $('.js-open-fancybox').on('click', function (e) {
    e.preventDefault()

    const id = $(this).attr('data-id')

    new Fancybox(
      [
        {
          src: '#' + id,
          type: 'inline',
          ...fancyboxSize
        }
      ],
      {
        ...config
      }
    )
  })

  window.SRPFancyBox = function () {
    $('.js-open-fancybox[data-id="politique-confidentialite"]').click()
  }
}
